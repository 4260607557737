@import "assets/styles/mixins";

.buttonPickerColor {
  padding: 5px;
  background-color: $white;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
  display: inline-flex;
  cursor: pointer;
  width: auto;
  height: auto;
  align-items: center;

  div {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    background-color: var(--tag-color);
    border: 1px solid gray;
  }

  p {
    text-transform: uppercase;
  }
}

.picker {
  position: absolute;
  z-index: 2;

  .overlay {
    border: none;
    background: transparent;
    position: fixed;
    inset: 0;
    cursor: default;

    &:hover {
      cursor: default;
    }
  }

  :global(.chrome-picker) {
    position: relative;
  }
}

:global(.dark) {
  .buttonPickerColor {
    background-color: $dark-1;
  }

  .picker {
    :global(.chrome-picker) {
      background-color: $dark-3 !important;

      input {
        background-color: $dark-1;
        color: $white !important;
      }

      svg {
        fill: $dark-2 !important;

        &:hover {
          background-color: $dark-1 !important;
        }
      }
    }
  }
}
